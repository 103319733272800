import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  Tabs,
  Tab,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Radio,
  IconButton,
  InputAdornment,
  TextField,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import classes from "./home.module.css";
import classesOne from "../Property/propertys.module.css";
import "./home.css";
import {
  ApproveIcon,
  DashboardLeaseIcon,
  DashboardRentIcon,
  DashboardSaleIcon,
  DashboardTotalIcon,
  DeactivateIcon,
  DeleteIcon,
  EditIcon,
  SoldIcon,
  ViewMoreIcon,
} from "../../Assets/Icons/icons";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import {
  adminDashboardCounts,
  deleteProperty,
  downloadPropertyDocs,
  getAllPropertiesDashboard,
  updatePropertyApi,
} from "../../Redux/Actions/propertyAction";
import { useNavigate } from "react-router-dom";
import { pageLoader } from "../../Redux/Actions/userActions";
import moment from "moment";
import { IoMdDownload } from "react-icons/io";
import { VictoryPie } from "victory";
import { VictoryLabel } from "victory-core";
import { FaArrowDown, FaArrowUp, FaRegCalendarAlt } from "react-icons/fa";
import DateRangePicker from "react-bootstrap-daterangepicker";
import dayjs from "dayjs";
import { range } from "../../Utilities/commonConstants";
import { numberWithCommas } from "../../Utilities/commonFunctions";
import AlertBox from "../../Components/AlertBox";
import SuccessAlert from "../../Components/SuccessAlert";
import ConfirmModal from "../../Components/confirmModel";
import { FaArrowUpLong } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import compare_icon from "../../Assets/Icons/compare_imag.svg";
import filter_icon from "../../Assets/Icons/filter_icon.svg";
import DatePicker from "react-datepicker";
import Filter_date_icon from "../../Assets/Icons/filter_date_icon.svg";
import "react-datepicker/dist/react-datepicker.css";
import { LinearProgressWithLabel } from "./ProgressBar";
import { formatDate, GetAllLocation, GetCompareData } from "./commonFunction";
import PropertyDetails from "../../Components/propertyDetail/PropertyDetails";
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorAlert, setErrorAlert] = useState(false);
  const [remainderPop, setRemainderPop] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successAlertDel, setSuccessAlertDel] = useState(false);
  const [remainderDetails, setRemainderDetails] = useState("");
  const [compare, setCompre] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [ViewDetails, setViewDetails] = useState(false);
  const [allproperties, setAllproperties] = useState({
    properties: [],
    TotalCount: 0,
  });
  const [value, setValue] = useState("Active");
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pieChartData, setPieChartData] = useState();
  const [age, setAge] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [newFromDate, setFromDate] = useState(
    dayjs().startOf("day").toISOString()
  );
  const [newToDate, setToDate] = useState(dayjs().endOf("day").toISOString());
  const [hoveredSlice, setHoveredSlice] = useState(null);
  const [pagination, setPagination] = useState(1);
  const [singleRow, setSingleRow] = useState(null);
  const [counts, setCounts] = useState(null);
  const { remainderStatus } = useSelector((state) => state.propertyData);
  const [locationValue, setLocationValue] = useState("");
  const [locality, setLocality] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startDateFinal, setStartDateFinal] = useState(formatDate(Date.now()));
  const [endDateFinal, setEndDateFinal] = useState(formatDate(Date.now()));
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [location, setLoaction] = useState([]);
  const [CurrentProperty,setCurrectProperty]=useState(null);
  const [compareData, setCompareData] = useState([]);
  const {
    propertyList,
    totalCount,
    feildsList,
    filtersList,
    inputs,
    fromDate,
    toDate,
    tableSort,
  } = useSelector((state) => state.propertyData);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSingleRow(row);
  };

  function handleGetDashboardCounts() {
    dispatch(
      adminDashboardCounts(
        newFromDate ? dayjs(newFromDate).format("YYYY-MM-DD") : "",
        newToDate ? dayjs(newToDate).format("YYYY-MM-DD") : "",
        setCounts
      )
    );
  }
  useEffect(() => {
    // Load counts and properties for today's date when the page loads
    handleGetDashboardCounts();
    handleGetDashProperty();
  }, []);
  function handleGetDashProperty() {
    dispatch(
      getAllPropertiesDashboard(
        setAllproperties,
        pagination,
        value,
        newFromDate ? dayjs(newFromDate).format("YYYY-MM-DD") : "",
        newToDate ? dayjs(newToDate).format("YYYY-MM-DD") : ""
      )
    );
  }

  const handleClickOption = (data) => {
    const userEntry = {
      property_status: data,
    };
    if (singleRow) {
      dispatch(pageLoader(true));
      dispatch(
        updatePropertyApi(
          singleRow?._id,
          userEntry,
          setErrorAlert,
          setSuccessAlert,
          handleGetDashProperty
        )
      );
      setAnchorEl(null);
    }
  };

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  const CloseAlert = () => {
    setDeleteConfirmationModal(false);
  };

  const handleClickDelete = () => {
    if (singleRow) {
      dispatch(pageLoader(true));
      dispatch(
        deleteProperty(singleRow?._id, setErrorAlert, setSuccessAlertDel)
      );
      setAnchorEl(null);
    }
  };

  const handleChangePagination = (e, count) => {
    setPagination(count);
  };

  function handleClearDate(e) {
    e.preventDefault();
    setFromDate();
    setToDate();
    setSelectedDate();
  }

  const handleDocuments = () => {
    if (singleRow) {
      dispatch(downloadPropertyDocs(singleRow?._id));
      setAnchorEl(null);
    }
  };

  function handlePropPrg(_count) {
    let actual_property = counts?.total_properties;

    let _cal = (_count / actual_property) * 100;
    if (!_cal) {
      return 0;
    }
    return _cal?.toFixed(2);
  }

  function handleUserPrg(_count) {
    let actual_property = counts?.activeUsers + counts?.inactiveUsers;

    let _cal = (_count / actual_property) * 100;

    if (!_cal) {
      return 0;
    }
    return _cal?.toFixed(2);
  }

  useEffect(() => {
    handleGetDashboardCounts();
  }, [newFromDate]);

  useEffect(() => {
    setPagination(1);
    handleGetDashProperty();
  }, [value, newFromDate]);

  useEffect(() => {
    handleGetDashProperty();
  }, [pagination]);

  useEffect(() => {
    if (successAlertDel) {
      setSuccessAlertDel(false);
      handleGetDashProperty();
    }
  }, [successAlertDel]);

  useEffect(() => {
    // if (queryParams?.tp === "route") {
    if (counts) {
      const data = [
        {
          x: `${counts?.landsplots_properties}%`,
          y: counts?.landsplots_properties,
        },
        {
          x: `${counts?.residential_properties}%`,
          y: counts?.residential_properties,
        },
        {
          x: `${counts?.commercial_properties}%`,
          y: counts?.commercial_properties,
        },
        {
          x: `${counts?.aggreculture_properties}%`,
          y: counts?.aggreculture_properties,
        },
      ];
      setPieChartData(data);
    }
    // } else {
    //   const data = [
    //     { x: `${reportData?.number_of_daysPresent}%`, y: reportData?.number_of_daysPresent,caption: "5 звезд"  },
    //     { x: `${reportData?.number_of_daysAbsent}%`, y: reportData?.number_of_daysAbsent,caption: "4 звезды"  },
    //     { x: `${reportData?.number_of_daysholiday}%`, y: reportData?.number_of_daysholiday ,caption: "3 звезды"},
    //   ];

    //   let a = [
    //     { x: "50%", y: 50, caption: "5 звезд" },
    //     { x: "25%", y: 25, caption: "4 звезды" },
    //     { x: "25%", y: 25, caption: "3 звезды" }
    //   ];
    //   setPieChartData(data);
    // }
  }, [counts]);

  useEffect(() => {
    if (newFromDate !== undefined && newToDate !== undefined) {
      setSelectedDate(
        `${moment(newFromDate).format("YYYY-MM-DD")} to ${moment(
          newToDate
        ).format("YYYY-MM-DD")}`
      );
    }
  }, [newFromDate, newToDate]);
  const getAllLocationAndLoca = async () => {
    try {
      const location = await GetAllLocation();
      setLoaction(location);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllLocationAndLoca();
    setTimeout(() => {
      setProgress(50); // Animate to 100% after the page loads
    }, 500); // You can adjust the delay time
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      date1: startDateFinal,
      date2: endDateFinal,
    };
    handlegetCompareData(data, locationValue, locality);
  };
  const handlegetCompareData = async (data, location, locality) => {
    try {
      const response = await GetCompareData(data, location, locality);
      setCompareData(response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setStartDateFinal(formatDate(startDate));
    setEndDateFinal(formatDate(endDate));
    console.log(startDateFinal, endDateFinal);
  }, [startDate, endDate]);
  const handleClose = () => {

    
// setCurrectProperty(data)
    setViewDetails(false);
  };
  const handleOpen = () => {
    setViewDetails(true);
  };
  console.log(CurrentProperty);
  
  return (
    <Box mb={7} height={"100%"}>
      {compare ? (
        <Box className={classes.compare_main_div}>
          <Box className={classes.compare_heading}>
            <h6>Compare</h6>
            <h6 onClick={() => setCompre(false)}>Back To Dashboard</h6>
          </Box>
          <Box className={classes.compare_filter_sec}>
            <div className={classes.comp_filter_heading}>
              <img src={filter_icon} alt="filter_icon" />
              <h6>Filter</h6>
            </div>
            <form className={classes.comp_filter_body} onSubmit={handleSubmit}>
              <div className={classes.comp_filter_body_left}>
                {/* Age Select */}
                <FormControl sx={{ m: 1, minWidth: 160, minHeight: 38 }}>
                  <InputLabel id="gender-select-label" sx={{ top: "-7px" }}>
                    Location
                  </InputLabel>
                  <Select
                    labelId="gender-select-label"
                    id="gender-select"
                    value={locationValue}
                    label="Location"
                    onChange={(e) => setLocationValue(e.target.value)}
                    sx={{ height: 38, borderRadius: "7px" }}
                  >
                    {location?.location?.map((data) => (
                      <MenuItem
                        key={data}
                        value={data}
                        sx={{
                          "&.MuiMenuItem-root": {
                            textAlign: "left",
                            flexDirection: "row !important", // Aligns the text
                          },
                        }}
                      >
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Gender Select */}
                <FormControl sx={{ m: 1, minWidth: 160, minHeight: 38 }}>
                  <InputLabel id="gender-select-label" sx={{ top: "-7px" }}>
                    locality
                  </InputLabel>
                  <Select
                    labelId="gender-select-label"
                    id="gender-select"
                    value={locality}
                    label="locality"
                    onChange={(e) => setLocality(e.target.value)}
                    sx={{ height: 38, borderRadius: "7px" }}
                  >
                    {location?.locality?.map((data) => (
                      <MenuItem
                        key={data}
                        value={data}
                        sx={{
                          "&.MuiMenuItem-root": {
                            textAlign: "left",
                            flexDirection: "row !important", // Aligns the text
                          },
                        }}
                      >
                        {data}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.filter_date_container}>
                  <DatePicker
                    selected={startDate}
                    onChange={(data) => setStartDate(data)}
                    className={classes.filter_date_picker}
                    dateFormat="dd/MM/yyyy"
                  />
                  <img src={Filter_date_icon} alt="Filter_date_icon" />
                </div>
                <div>&</div>
                <div className={classes.filter_date_container}>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className={classes.filter_date_picker}
                    dateFormat="dd/MM/yyyy"
                  />
                  <img src={Filter_date_icon} alt="Filter_date_icon" />
                </div>
              </div>

              <div className={classes.comp_filter_body_right}>
                <button type="submit">COMPARE</button>
              </div>
            </form>
          </Box>
          <Box className={classes.compare_body}>
            <Box className={classes.user_details}>
              <div className={classes.user_details_heading}>
                <h6>Users Comparitive Data</h6>
                <div className={classes.user_details_heading_right}>
                  <div></div>
                  <div className={classes.user_details_heading_right_date}>
                    <div style={{ backgroundColor: "#21afa2" }}></div>
                    <p>
                      {startDateFinal?.month_in_letter}, {startDateFinal?.year}
                    </p>
                  </div>
                  <div className={classes.user_details_heading_right_date}>
                    <div></div>
                    <p>
                      {endDateFinal?.month_in_letter}, {endDateFinal?.year}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.user_details_bar}>
                {compareData?.userCompare
                  ?.slice()
                  .reverse()
                  ?.map((data, index) => (
                    <div className={classes.user_details_bar_details}>
                      <div className={classes.user_details_bar_name}>
                        <p>
                          {data.type_of_user === "owner"
                            ? "Owner"
                            : data.type_of_user === "agent"
                            ? "Agent"
                            : data.type_of_user === "active_user"
                            ? "Active User"
                            : "Inactive"}
                          {/* Owners{" "} */}
                          <span>
                            {Number(data?.percentage_change) > 0 ? (
                              <FaArrowUp color="#00CB4F" />
                            ) : (
                              <FaArrowDown color="#FF0000" />
                            )}
                          </span>{" "}
                          {Number(data?.percentage_change) > 0 ? (
                            <span style={{ color: "#00CB4F" }}>
                              {Math.abs(data?.percentage_change)} %
                            </span>
                          ) : (
                            <span style={{ color: "#f50414" }}>
                              {Math.abs(data?.percentage_change)} %
                            </span>
                          )}
                        </p>
                      </div>
                      <div className={classes.user_details_bar_main}>
                        <div className={classes.user_details_bar_rating}>
                          <p>{data.count_in_month1}</p>
                          <LinearProgressWithLabel
                            max={data.fullValue}
                            value={Number(data.progress1)}
                            color={"#1DAFA5"}
                          />
                        </div>
                        <div className={classes.user_details_bar_rating}>
                          <p>{data.count_in_month2}</p>
                          <LinearProgressWithLabel
                            max={data.fullValue}
                            value={Number(data.progress2)}
                            color={"#14716D"}
                          />
                        </div>
                      </div>
                      <div></div>
                    </div>
                  ))}
              </div>
            </Box>
            <Box className={classes.property_details}>
              <div className={classes.user_details_heading}>
                <h6>Properties Comparitive Data</h6>
                <div className={classes.user_details_heading_right}>
                  <div></div>
                  <div className={classes.user_details_heading_right_date}>
                    <div style={{ backgroundColor: "#21afa2" }}></div>
                    <p>
                      {startDateFinal?.month_in_letter}, {startDateFinal?.year}
                    </p>
                  </div>
                  <div className={classes.user_details_heading_right_date}>
                    <div></div>
                    <p>
                      {endDateFinal?.month_in_letter}, {endDateFinal?.year}
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.user_details_bar}>
                {compareData?.propertyCompare?.map((data, index) => (
                  <div className={classes.user_details_bar_details} key={index}>
                    <div className={classes.user_details_bar_name}>
                      <p>
                        {data?.kind_of_property}
                        <span>
                          {Number(data?.percentage_change) > 0 ? (
                            <FaArrowUp color="#00CB4F" />
                          ) : (
                            <FaArrowDown color="#FF0000" />
                          )}
                        </span>{" "}
                        <span style={{ color: "#FC4F3F" }}>
                          {Number(data?.percentage_change) > 0 ? (
                            <span style={{ color: "#00CB4F" }}>
                              {Math.abs(data?.percentage_change)} %
                            </span>
                          ) : (
                            <span style={{ color: "#f50414" }}>
                              {Math.abs(data?.percentage_change)} %
                            </span>
                          )}
                          {/* {data?.percentage_change} % */}
                        </span>
                      </p>
                    </div>
                    <div className={classes.user_details_bar_main}>
                      <div className={classes.user_details_bar_rating}>
                        <p>{data?.count_in_date1}</p>
                        <LinearProgressWithLabel
                          value={data?.progress1}
                          color={"#14716D"}
                        />
                      </div>
                      <div className={classes.user_details_bar_rating}>
                        <p>{data?.count_in_date2}</p>
                        <LinearProgressWithLabel
                          value={data?.progress2}
                          color={"#1DAFA5"}
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                ))}
              </div>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box className="d-flex justify-content-between align-items-center">
            <Box className={classes.compareBtn} onClick={() => setCompre(true)}>
              <img src={compare_icon} alt="compare_icon" />
              <Typography>Compare</Typography>
            </Box>
            <Box className={classes.datePicker1}>
              <span>Select Period :</span>
              <DateRangePicker
                initialSettings={{ ranges: range }}
                alwaysShowCalendars={true}
                onApply={handleEvent}
              >
                <div style={{ position: "relative" }}>
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    size="small"
                    autoComplete="off"
                    placeholder="Select Date Period"
                    value={selectedDate ? selectedDate : ""}
                    className={`${
                      selectedDate ? classes.dataInputTwo : classes.dataInputTwo
                    }`}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            {!selectedDate && <FaRegCalendarAlt />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {selectedDate && (
                    <IoIosCloseCircle
                      className={classes.date_clear}
                      onClick={(e) => handleClearDate(e)}
                    />
                  )}
                </div>
              </DateRangePicker>
            </Box>
          </Box>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4}>
                <Box className={classes.count_box}>
                  <Typography>
                    <DashboardSaleIcon />
                  </Typography>
                  <Box>
                    <h2>{counts?.sale_properties}</h2>
                    <span>Properties for sale</span>
                  </Box>
                  {!selectedDate && (
                    <h5>
                      {" "}
                      <FaArrowUpLong /> {counts?.salePropertyPercentage}% (30
                      days)
                    </h5>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Box className={classes.count_box}>
                  <Typography>
                    <DashboardRentIcon />
                  </Typography>
                  <Box>
                    <h2>{counts?.rent_properties}</h2>
                    <span>Properties for rent / lease</span>
                  </Box>
                  {!selectedDate && (
                    <h5>
                      {" "}
                      <FaArrowUpLong /> {counts?.rentPropertyPercentage}% (30
                      days)
                    </h5>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Box className={classes.count_box}>
                  <Typography>
                    <DashboardTotalIcon />
                  </Typography>
                  <Box>
                    <h2>{counts?.total_properties}</h2>
                    <span>Total properties</span>
                  </Box>
                  {!selectedDate && (
                    <h5>
                      {" "}
                      <FaArrowUpLong /> {counts?.totalPropertyPercentage}% (30
                      days)
                    </h5>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.no_of_users}>
                  <Box className={classes.user_head}>
                    <Typography fontWeight={600}>No. of users</Typography>
                    <h6>{counts?.activeUsers + counts?.inactiveUsers}</h6>
                  </Box>
                  <Box mt={3}>
                    <Box mb={2}>
                      <Box className={classes.sub_head}>
                        <Typography>Owners</Typography>
                        <span>{counts?.owner_properties}</span>
                      </Box>
                      <Box className={classes.user_percent}>
                        <Typography
                          width={`${handlePropPrg(counts?.owner_properties)}%`}
                        ></Typography>
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <Box className={classes.sub_head}>
                        <Typography>Agents</Typography>
                        <span>{counts?.agent_properties}</span>
                      </Box>
                      <Box className={classes.user_percent}>
                        <Typography
                          width={`${handlePropPrg(counts?.agent_properties)}%`}
                        ></Typography>
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <Box className={classes.sub_head}>
                        <Typography>Active</Typography>
                        <span>{counts?.activeUsers}</span>
                      </Box>
                      <Box className={classes.user_percent}>
                        <Typography
                          width={`${handleUserPrg(counts?.activeUsers)}%`}
                        ></Typography>
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <Box className={classes.sub_head}>
                        <Typography>Inactive</Typography>
                        <span>{counts?.inactiveUsers}</span>
                      </Box>
                      <Box className={classes.user_percent}>
                        <Typography
                          width={`${handleUserPrg(counts?.inactiveUsers)}%`}
                        ></Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.chart_part}>
                  <Typography fontWeight={600}>Total Properties</Typography>
                  <Box
                    display={"grid"}
                    alignItems={"center"}
                    gridTemplateColumns={"32% 68%"}
                  >
                    <div className={classes.pieChartContainer}>
                      <svg viewBox="0 0 450 450" width="200px">
                        <VictoryPie
                          standalone={false}
                          width={450}
                          height={450}
                          data={pieChartData}
                          innerRadius={115}
                          colorScale={[
                            "#9EF1E4",
                            "#14716D",
                            "#CFF8F2",
                            "#1DAFA5",
                          ]}
                          labels={() => null} // Hide labels
                          style={{ labels: { fontSize: 20, fill: "white" } }}
                          // labels={({ datum }) => (datum === hoveredSlice ? `${datum.label}` : null)}
                          // style={{
                          //   data: {
                          //     fillOpacity: ({ datum }) => (datum === hoveredSlice ? 1 : 0.7),
                          //     stroke: ({ datum }) => (datum === hoveredSlice ? "#000" : "none"),
                          //     strokeWidth: 2,
                          //   },
                          //   labels: { fontSize: 20, fill: "white" }
                          // }}
                          // events={[
                          //   {
                          //     target: "data",
                          //     eventHandlers: {
                          //       onMouseOver: (evt, clickedProps) => {
                          //         setHoveredSlice(clickedProps.datum);
                          //         return [{ target: "labels", mutation: () => ({ active: true }) }];
                          //       },
                          //       onMouseOut: () => {
                          //         setHoveredSlice(null);
                          //         return [{ target: "labels", mutation: () => ({ active: false }) }];
                          //       },
                          //     },
                          //   },
                          // ]}
                        />
                        <VictoryLabel
                          textAnchor="middle"
                          verticalAnchor="middle"
                          x={225}
                          y={230}
                          text={["Total", parseInt(counts?.total_properties)]}
                          // text={["Total"]}
                          style={[
                            { fontSize: 42, fontWeight: 500, fill: "#99B2C6" },
                            {
                              fontSize: "38px",
                              fontWeight: 600,
                              fill: "#222222",
                              paddingTop: "8px",
                            },
                          ]}
                        />
                      </svg>
                    </div>
                    <Box className={classes.chart_infos}>
                      <Box className={classes.single_chart_info}>
                        <Typography
                          className={classes.single_chart_info_color}
                          bgcolor={"#9EF1E4"}
                        ></Typography>
                        <Box className={classes.single_chart_info_text}>
                          <span>Lands/plots</span>
                          {!selectedDate && (
                            <Typography>
                              {" "}
                              <FaArrowUpLong /> {counts?.landsPlotsPercentage}%
                              (30 days)
                            </Typography>
                          )}
                          <h4>{counts?.landsplots_properties}</h4>
                        </Box>
                      </Box>
                      <Box className={classes.single_chart_info}>
                        <Typography
                          className={classes.single_chart_info_color}
                          bgcolor={"#14716D"}
                        ></Typography>
                        <Box className={classes.single_chart_info_text}>
                          <span>Residential</span>
                          {!selectedDate && (
                            <Typography>
                              {" "}
                              <FaArrowUpLong />{" "}
                              {counts?.residentialPropertyPercentage}% (30 days)
                            </Typography>
                          )}
                          <h4>{counts?.residential_properties}</h4>
                        </Box>
                      </Box>
                      <Box className={classes.single_chart_info}>
                        <Typography
                          className={classes.single_chart_info_color}
                          bgcolor={"#CFF8F2"}
                        ></Typography>
                        <Box className={classes.single_chart_info_text}>
                          <span>Commercial</span>
                          {!selectedDate && (
                            <Typography>
                              {" "}
                              <FaArrowUpLong />{" "}
                              {counts?.commercialPropertyPercentage}% (30 days)
                            </Typography>
                          )}
                          <h4>{counts?.commercial_properties}</h4>
                        </Box>
                      </Box>
                      <Box className={classes.single_chart_info}>
                        <Typography
                          className={classes.single_chart_info_color}
                          bgcolor={"#1DAFA5"}
                        ></Typography>
                        <Box className={classes.single_chart_info_text}>
                          <span>Agricultural/ Farmlands</span>
                          {!selectedDate && (
                            <Typography>
                              {" "}
                              <FaArrowUpLong />{" "}
                              {counts?.aggreculturePropertyPercentage}% (30
                              days)
                            </Typography>
                          )}
                          <h4>{counts?.aggreculture_properties}</h4>
                        </Box>
                      </Box>
                      <Box className={classes.single_chart_info}>
                        <Typography
                          className={classes.single_chart_info_color}
                          bgcolor={"#1DAFA5"}
                        ></Typography>
                        <Box className={classes.single_chart_info_text}>
                          <span>New Project</span>
                          {!selectedDate && (
                            <Typography>
                              {" "}
                              <FaArrowUpLong />{" "}
                              {counts?.total_newPropertiesPercentage}% (30 days)
                            </Typography>
                          )}
                          <h4>{counts?.new_properties}</h4>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3}>
            <Box className="table-tabs">
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="wrapped label tabs example"
              >
                <Tab
                  value="Active"
                  label={`Activated ${
                    value === "Active" ? `(${allproperties?.TotalCount})` : ""
                  }`}
                />
                <Tab
                  value="Pending Approval"
                  label={`Pending Approval ${
                    value === "Pending Approval"
                      ? `(${allproperties?.TotalCount})`
                      : ""
                  }`}
                />
                <Tab
                  value="Sold"
                  label={`Sold ${
                    value === "Sold" ? `(${allproperties?.TotalCount})` : ""
                  }`}
                />
                <Tab
                  value="Deactivated"
                  label={`Deactivated ${
                    value === "Deactivated"
                      ? `(${allproperties?.TotalCount})`
                      : ""
                  }`}
                />
              </Tabs>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/property")}
              >
                {" "}
                View All
              </span>
            </Box>
            <TableContainer
              component={Paper}
              className={classesOne.TableContainer}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className={classesOne.tableHead}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>
                      Type
                      {/* <span style={{ cursor: "pointer" }}>
                       <ArrowsIcon />
                     </span> */}
                    </TableCell>
                    <TableCell>
                      Location
                      {/* <span style={{ cursor: "pointer" }}>
                       <ArrowsIcon />
                     </span> */}
                    </TableCell>
                    <TableCell style={{ minWidth: 130 }}>Date</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Email ID</TableCell>
                    <TableCell style={{ minWidth: 130 }}>Price</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(allproperties?.properties) &&
                    allproperties?.properties?.map((row, index) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        className={classesOne.table_body}
                        // onClick={() => navigate(`/property/view-property/${row._id}`)}
                      >
                        <TableCell>
                          <div className={classesOne.TableRowImageContainer}>
                            <img
                              src={
                                row.photos[0]?.image
                                  ? `${process.env.REACT_APP_API_URL}/${row.photos[0]?.image}`
                                  : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                              }
                              alt="property-image"
                              height={"40px"}
                              width={"40px"}
                            />
                            <Typography>{row.city}</Typography>
                          </div>
                        </TableCell>
                        <TableCell>{row.kind_of_property}</TableCell>
                        <TableCell>{row.location}</TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>{row.created_user.name}</TableCell>
                        <TableCell>{row.created_user.phone}</TableCell>
                        <TableCell>{row.created_user.email}</TableCell>
                        <TableCell>
                          {row.price?.expected_price
                            ? `₹ ${numberWithCommas(row.price?.expected_price)}`
                            : "- "}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{ cursor: "pointer" }}
                            onClick={(e) => handleClick(e, row)}
                          >
                            <ViewMoreIcon />
                          </Typography>
                          {row.property_status !== "Deleted" && (
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={() => {
                                setSingleRow(null);
                                setAnchorEl(null);
                              }}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              {row.property_status === "Sold" ? (
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  gap={"8px"}
                                  p={2}
                                >
                                  <Button
                                    className={classesOne.popper_btns}
                                    onClick={() =>
                                      navigate(
                                        `/property/edit-property/${singleRow?._id}`
                                      )
                                    }
                                  >
                                    <EditIcon /> Edit
                                  </Button>
                                  <Button
                                    className={classesOne.popper_btns}
                                    onClick={() =>
                                      setDeleteConfirmationModal(true)
                                    }
                                  >
                                    <DeleteIcon /> Delete{" "}
                                  </Button>
                                </Box>
                              ) : (
                                <Box
                                  display={"flex"}
                                  flexDirection={"column"}
                                  gap={"8px"}
                                  p={2}
                                >
                                  {row.property_status === "Pending_Approval" &&
                                  singleRow?.user_type != "Agent" ? (
                                    <>
                                      <Button
                                        className={classesOne.popper_btns}
                                        onClick={handleDocuments}
                                      >
                                        <IoMdDownload /> Download
                                      </Button>
                                    </>
                                  ) : null}

                                  {(row.property_status === "Deactivated" ||
                                    row.property_status ===
                                      "Pending_Approval") && (
                                    <Button
                                      className={classesOne.popper_btns}
                                      onClick={() =>
                                        handleClickOption("Active")
                                      }
                                    >
                                      <ApproveIcon /> Approve
                                    </Button>
                                  )}
                                  {row.property_status !== "Drafted" ? (
                                    <Button
                                      className={classesOne.popper_btns}
                                      onClick={() => {
                                        navigate(
                                          `/property/edit-property/${singleRow._id}`
                                        );
                                        setAnchorEl(null);
                                      }}
                                    >
                                      <EditIcon /> Edit
                                    </Button>
                                  ) : (
                                    <Button
                                      className={classesOne.popper_btns}
                                      onClick={() => {
                                        // navigate(`/property/edit-property/${row._id}`);
                                        setRemainderPop(true);
                                        setAnchorEl(null);
                                      }}
                                    >
                                      <EditIcon /> Send Reminder
                                    </Button>
                                  )}
                                  {row.property_status !==
                                  "Pending_Approval" ? (
                                    <Button
                                      className={classesOne.popper_btns}
                                      onClick={() => handleClickOption("Sold")}
                                    >
                                      <SoldIcon /> Mark as Sold
                                    </Button>
                                  ) : null}
                                  {row.property_status !== "Deactivated" && (
                                    <Button
                                      className={classesOne.popper_btns}
                                      onClick={() =>
                                        handleClickOption("Deactivated")
                                      }
                                    >
                                      <DeactivateIcon /> Deactivate
                                    </Button>
                                  )}

                                  <Button
                                    className={classesOne.popper_btns}
                                    onClick={() =>
                                      setDeleteConfirmationModal(true)
                                    }
                                  >
                                    <DeleteIcon /> Delete{" "}
                                  </Button>
                                  <Button
                                    className={classesOne.popper_btns}
                                    onClick={() => handleOpen()}
                                  >
                                    <DeleteIcon /> View Details{" "}
                                  </Button>
                                  <PropertyDetails
                                  row={row}
                                    ViewDetails={ViewDetails}
                                    handleClose={handleClose}
                                  />
                                </Box>
                              )}
                            </Popover>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {allproperties?.TotalCount > 0 && (
              <Box
                display={"flex"}
                justifyContent={"end"}
                my={3}
                className="property_pagination"
              >
                <Pagination
                  count={Math.ceil(allproperties?.TotalCount / 10)}
                  shape="rounded"
                  sx={{ textAlign: "end" }}
                  onChange={handleChangePagination}
                />
              </Box>
            )}
            {successAlert && (
              <SuccessAlert
                type="success"
                message={"Status Updated Successfully"}
                stateName={() => setSuccessAlert(false)}
              />
            )}
            {deleteConfirmationModal && (
              <ConfirmModal
                modalClose={CloseAlert}
                heading={"Delete Confirmation"}
                subHeading={`Are you sure want to delete this property?`}
                callback={handleClickDelete}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Home;
